// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
window.bootstrap = require('bootstrap');

import "./bootstrap_custom.js";
import "./common/sweetalert/sweetalert"
import "@fortawesome/fontawesome-free/css/all"
import "./common/slickjs/slickjs"
import "./common/cookie/cookieconsent.min.js"
// import "./smoothScroll.js";
import "./scroll.js";


import("../styles/style.scss");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

let sections = [];

$(function(){
    // var tooltipItem = document.querySelector('.tooltip-item')
    // var tooltip = new bootstrap.Tooltip(tooltipItem , {
    //     boundary: document.body
    // })


    $(".close-button").on("click", function(e){
        $('#header').toggleClass('is-opened')
        $('body').toggleClass('is-opened')

        e.preventDefault(true)
    })

    $(".mobile-card-title").on("click", function(e){
        $(this).toggleClass("active");
        var target = $(this).parents(".image-card-wrapper");
        target.find(".mobile-card, .image-card").slideToggle();

        e.preventDefault();
    })

    $(".go-top").on("click", function(e){
        window.scrollTo(0,0);
        e.preventDefault(true)
    })

    $(".footer-slide-to").on("click", function(e){
        var target = $(this).data("target")
        if($(target).length > 0){
            window.scrollTo(0, $(target).offset().top - $("#header").innerHeight() - 15);
            e.preventDefault(true)
        }
    })

})

// load font
function loadFont(url) {
    // the 'fetch' equivalent has caching issues
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
            let css = xhr.responseText
            css = css.replace(/}/g, 'font-display: swap; }')

            const head = document.getElementsByTagName('head')[0]
            const style = document.createElement('style')
            style.appendChild(document.createTextNode(css))
            head.appendChild(style)
        }
    }
    xhr.send()
}

loadFont(
    'https://fonts.googleapis.com/css?family=Noto+Sans+TC:100,300,400,700'
)

loadFont('https://use.typekit.net/spm3wuc.css')
import $ from 'jquery';
// window.$ = window.jQuery = $;
import 'slick-carousel';

document.addEventListener('DOMContentLoaded', function() {
  if($(".case-gallery").length > 0){
      $(".case-gallery").slick({
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
          dots: true,
          swip: true,
          variableWidth: true,
          autoplay: false,
          autoplaySpeed: 5000,
          centerMode: true,
          prevArrow: '<button type="button" class="slick-prev"><span class="circle">Previous</span></button>',
          nextArrow: '<button type="button" class="slick-next"><span class="circle">Next</span></button>',
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                variableWidth: true
              }
            }
          ]
      });
  }

  if($(".info-imgs").length > 0){
    $(".info-imgs").slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        swip: true,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: '<button type="button" class="slick-prev"><span class="circle">Previous</span></button>',
        nextArrow: '<button type="button" class="slick-next"><span class="circle">Next</span></button>'
    });
}
})

import 'waypoints/lib/jquery.waypoints.min.js';

window.scrollTo(0, 0)

function pageInit(){
    $(".article-section").each(function(e, item){
        var target = $(this);
        var animateName = $(this).data("animate-name");
    
        target.waypoint(function(direction) {
            target.addClass(animateName);
        }, {
            offset: '100%'
        });
        
    })
}

window.addEventListener(
    'DOMContentLoaded',
    function () {
        if($(".page-content").length > 0){
            pageInit();
        }
    },
    true
)
